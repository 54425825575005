import type { TranslationKey } from '~/translations.server';
import { useRootLoaderData } from './useRootLoaderData';

export const useTranslate = () => {
  const rootLoaderData = useRootLoaderData();

  return ( key: TranslationKey, defaultValue?: string ) => {
    return ( rootLoaderData?.translations[ key ] ?? defaultValue ?? key ) as string;
  }
}
